body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececec !important;
  background: #ececec !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .table-container {
  margin: 15px;
} */
.table-container input.form-control {
  padding: 20px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  padding: 0 30px;
  height: 45px;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  border: 0;
  background: #f8f8f8;
  border-radius: 55px;
  margin-right: 1%;
  width: 70%;
}
.table-container .react-bs-table-search-form span.input-group-btn button {
  background: #f39200;
  border-radius: 55px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  /* width: 34%; */
  border: 1px solid #f39200;
  transition: 0.2s ease;
  justify-content: center;
  height: 45px;
}
.table-container button#pageDropDown {
  background: #01223a;
  border-color: #01223a;
}
.table-container .page-item.active .page-link {
  background: rgb(2, 166, 156) !important;
  border-color: rgb(2, 166, 156) !important;
}
.linkable {
  cursor: pointer !important;
}
a.nav-link.nav-link-vertival-custom.actived {
  background: rgba(255, 255, 255, 0.1);
  color: #fff !important;
  font-weight: 800;
  border-right: 5px solid rgb(2, 166, 156);
}
.navmobile-color .collapse .nav-item a.nav-link {
  color: #fff !important;
}
.navmobile-color .collapse .nav-item a.nav-link.actived {
  background: rgba(255, 255, 255, 0.1);
  color: #fff !important;
  /* font-weight: 800; */
  border-right: 5px solid rgb(2, 166, 156);
}

.chart-item-title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #252733;
}
.empty-suggestion {
  color: #ff0000;
  display: block;
  padding: 11px;
  font-weight: 600;
}
.video-viemo .player .vp-title .vp-title-header {
  display: none !important;
}
.error-row {
  width: 100%;
}
.date-counter-container {
  margin: 20px 0;
  color: #1f497d;
  font-weight: 600;
}
.start-date {
  font-weight: 600;
}
span.counter-item {
  margin-left: 18px;
  padding: 12px;
  background: #ececec;
  border-radius: 5px;
}
.goto-forum-item-btn {
  background: #1f497d;
  padding: 14px;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
  margin-top: 5px;
  display: block;
  text-align: center;
  border: 1px solid #1f497d;
}
.goto-forum-item-btn:hover {
  background: transparent;
  color: #1f497d;
  text-decoration: none;
}
.page-item.active .page-link {
  background-color: #1f497d !important;
  border-color: #1f497d !important;
  color: #fff !important;
  font-weight: 900;
}
li.page-item a {
  color: #1f497d !important;
}
.page-item:first-child .page-link span {
  color: #1f497d !important;
}
.page-item.disabled .page-link span {
  color: #1f497d !important;
}
.cm-sender-email {
  font-size: 15px;
  font-style: italic;
}
.badge-module-name {
  font-size: 20px;
  font-weight: 600;
  color: #1f497d;
}
.badge-blue {
  background: #1f497d !important;
}
.badge-orange {
  background: #f39200 !important;
}
.badge-green {
  background: #148f77 !important;
}
.theme-background-color {
  background-color: #1f497d !important;
}
.row.contact-support-row {
  margin-bottom: 15px;
}
.gfa-alert-info {
  background: #1f497d33 !important;
  border-color: #1f497d !important;
  color: #1f497d !important;
  text-align: justify !important;
}
.contact-support-link {
  color: #1f497d !important;
  font-weight: 800;
}

img.zoom-img {
  transform: scale(0.9) !important;
  z-index: 9999999999;
  width: 100%;
  /* margin-top: 30% !important; */
}
.image-zoomable-modal .modal-lg {
  min-width: 1000px !important;
}
.zoomTarget {
  cursor: zoom-in !important;
}
img.is-zoomed-image {
  width: 100%;
}
.regenerate-apprenant-password-icon {
  color: #fff !important;
  width: 16px;
}
.gfa-btn-action-primary {
  background: #1f497d !important;
  border-color: #1f497d !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gfa-btn-action-primary .regenerate-apprenant-password-icon:hover {
  background: transparent !important;
  border-color: #1f497d !important;
  color: #1f497d;
}
.actions-buttons-container {
  text-align: center;
}
button.btn.btn-success.react-bs-table-csv-btn.hidden-print {
  background: #f39200 !important;
  border-color: #f39200 !important;
  padding: 11px;
  margin-bottom: 9px;
  border-radius: 50px;
}
.user-infos-modal-col {
  border: 1px solid #ececec;
  padding: 15px 6px;
  border-radius: 10px;
  font-size: 18px;
  margin-bottom: 5px;
}
.user-infos-modal-item-label {
  font-weight: 600;
}
.user-infos-modal-item-content {
  color: #1f497d !important;
  font-weight: 700;
}
span.badge-success.gfa-badge-success,
span.badge-danger.gfa-badge-danger {
  padding: 7px;
  font-weight: 600;
  border-radius: 50px;
}
.title h5.quizz-label {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  text-align: initial !important;
}
.link-to-evaluation {
  background: #f39200;
  border-color: #f39200;
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  border-radius: 25px !important;
  font-weight: 600;
}
.link-to-evaluation:hover {
  background: #fff;
  color: #f39200;
}
.no-identified-col .gfa-alert-danger {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
/* div#g-recaptcha > div {
  width: 100% !important;
}
div#g-recaptcha {
  width: 100% !important;
}
div#g-recaptcha iframe {
  width: 100% !important;
}
div#rc-anchor-container {
  width: 99% !important;
  border-radius: 10px;
} */
.certified-count {
  color: #1f497d !important;
  font-weight: 700;
  font-size: 25px !important;
  display: inline-block;
}
b.orange-colored {
  color: #f39200 !important;
}
a.defaru-linking {
  font-weight: 800;
  color: #1f497d !important;
}
.gfa-image-container {
  height: 250px;
}
.center-paragraphe {
  text-align: center;
}
.gfa-blue-color {
  color: #1f497d !important;
  font-weight: 600 !important;
}
.pdf-created-link-container {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 30px;
  width: 100% !important;
}
.pdf-created-link {
  width: 100% !important;
  background: #1f497d;
  padding: 20px;
  color: #ffff;
  font-weight: 600;
  border-radius: 50px;
  text-decoration: initial;
  border: 1px solid #1f497d !important;
  transition: all 0.5s ease;
}
.pdf-created-link:hover {
  background: #fff !important;
  color: #1f497d !important;
}
.icon-eye {
  width: 16px;
}

/* ************************* New style */
body {
  background: #ececec;
}
.empty-fluidy {
  padding: 20px 125px;
  background: #fff !important;
}
.logo-item {
  font-size: 25px;
  color: #000;
}
.logo-secondy {
  font-weight: 800;
}
.login-page-row {
  margin-top: 3%;
  background: #fff;
  border-radius: 10px;
}
.left-side-col {
  background: #043b63 !important;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.login-form-container {
  padding: 100px 45px;
}
.login-title-label {
  font-size: 35px;
  color: #000;
  margin-bottom: 55px;
}
#login-form .input-group {
  border-bottom: 1px solid #ececec;
}
#login-form .input-with-icon,
#login-form .span-input-icon {
  background: transparent;
  border: none !important;
  border-radius: 0 !important;
}
.alling-header {
  margin-left: -15px;
  margin-right: -15px;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 5%);
}
.hiddenable-navlink-label {
  display: none;
}

/* .collapse .nav-item {
  display: none;
} */

.mobile .collapse .nav-item {
  display: block !important;
}

/* .nav-item-vertical-custom a.active {
  background-color: #eee;
} */
.hoverable:hover span.hiddenable-navlink-label {
  display: inline-block;
}
.hoverable:hover .collapse .nav-item {
  display: block;
}
.stat-conteny p {
  color: #ffffff;
}
.parent-label-card-statistics {
  font-weight: 800;
}
.child-label-card-statistics {
  margin-top: -1px;
}
.label-stat-counter {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  font-family: Montserrat;
}
.search-form-container {
  position: relative;
}
.search-form-content label {
  position: absolute;
  top: 20%;
  left: 4%;
}
input.form-control.search-input {
  width: 250px;
  border-radius: 8px;
  padding: 1rem 2rem !important;
}
.headering {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-others-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.add-item-content svg {
  width: 48px;
  height: 48px;
}
.notification-item-content {
  position: relative;
}
.notification-counter {
  position: absolute;
  display: inline-block;
  top: -4px;
  width: 20px;
  height: 20px;
  background: red;
  text-align: center;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  left: 50%;
}
img.user-avatar {
  border-radius: 8px;
  width: 45%;
}
svg.search-icony {
  width: 20px;
  height: 20px;
}
.navbar-vertical-customisation-v2:hover span.hiddenable-navlink-label {
  display: inline-block;
}
.page-content-container {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 30px;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 5%);
}
.page-first-row-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-title {
  font-size: 25px;
  color: #000;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adding-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 12%;
  margin-right: 5px;
}
.form-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-filter-row.beneficiaire-filter {
  width: 60%;
}
.export-btn-container {
  width: 50%;
  text-align: right;
}
.export-btn {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border-color: transparent !important;
  width: 29%;
}
.export-btn-label {
  color: #000;
}
.export-btn:hover {
  background-color: transparent !important;
  border: 1px solid #02a69c !important;
}
.export-btn:hover .export-btn-label {
  color: #02a69c !important;
}
.entreprise {
  color: rgb(0 0 0 / 24%) !important;
}
.action-btn {
  background: transparent;
}
.table-actions-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumbs-and-actions-row {
  margin-top: 6%;
  background: #fff;
  padding-left: 9%;
  padding-top: 23px;
  padding-bottom: 14px;
}
.breadcrumbs-container svg {
  width: 10px;
}
.brdc-item {
  color: rgba(0, 0, 0, 0.7) !important;
}
.brdc-item:hover {
  text-decoration: none !important;
  color: #01223a !important;
}
.brdc-item.active-brdc-item {
  color: #01223a !important;
  font-weight: 500;
}
.ben-profile-container {
  margin-top: 3%;
}
.profile-infos-ben,
.wifes-infos-container,
.childs-infos-container {
  background: #ffffff;
  border-radius: 3px;
}
.wifes-infos-container {
  margin-bottom: 25px;
}
.wifes-infos-container,
.childs-infos-container {
  padding: 25px 20px;
}
.profile-infos-ben {
  padding: 18px 25px;
  margin-bottom: 20px;
}
.section-light-title {
  font-size: 16px;
  color: #0c0b0b;
}
.profile-infos-ben .section-light-title {
  font-size: 16px;
  color: #0c0b0b;
  display: flex;
  justify-content: space-between;
}
.bnf-edit-btn {
  background: transparent;
}
.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #c4c4c4;
}
.profile-avatar-container {
  text-align: center;
  margin: 40px 0;
}

.ben-name {
  font-size: 24px;
  color: #0c0b0b;
  text-transform: capitalize;
  font-weight: 500;
}
.ben-others-infos-container {
  display: flex;
  flex-direction: column;
}
.ben-others-infos-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 16px;
}
.ben-others-infos-item-value {
  color: #0c0b0b;
  font-weight: 500;
}
.status-header-actions-container {
  display: flex;
  align-items: initial;
  justify-content: space-between;
  /* width: 100%; */
}
.header-actions-container {
  display: flex;
  width: 70%;
  justify-content: space-around;
}
.status-value {
  font-weight: 600;
}
/* .wifes-infos-container .adding-item-container {
  width: 30%;
} */
.easypm-modal-title {
  color: #0c0b0b;
  font-size: 18px;
}
.easypm-modal-header {
  border-bottom: none !important;
  padding-left: 25px;
  padding-right: 25px;
}
.easypm-modal-body {
  padding-left: 25px;
  padding-right: 25px;
}
.crud-form-group label {
  color: #0c0b0b !important;
  font-size: 13px;
}
.crud-form-control {
  padding: 3px 22px;
  border-radius: 10px !important;
}
.input-radio-container {
  padding-top: 22px;
  padding-bottom: 60px;
}
.input-radio-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-radio-item {
  border: 0.5px solid #959595;
  padding: 13px;
  border-radius: 10px;
  width: 40%;
  display: flex;
}

input[type="radio"].input-radio-form-control {
  width: 20px;
  height: 20px;
}

span.input-radio-item-label {
  color: #515151;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.045em;
}
input[type="radio"].input-radio-form-control:checked:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 3px;
  margin-right: 5px;
  background-clip: content-box;
  border: 1px solid #02a69c;
  background-color: #02a69c;
  border-radius: 50%;
}
input[type="radio"].input-radio-form-control:checked + label:before {
  background-color: #02a69c;
}
.crud-form-action-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.crud-form-action-btn-container button {
  width: 100% !important;
  padding: 15px;
}
.crud-submit-btn {
  background: #02a69c !important;
  border-color: #02a69c !important;
  border-radius: 10px;
}
.crud-submit-btn:hover {
  color: #02a69c !important;
  background: transparent !important;
}
.cancelleb-btn:hover {
  background: #ff0000 !important;
  border-color: #ff0000 !important;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
}
.cancelleb-btn {
  background: transparent;
  color: #515151;
  font-size: 17px;
  font-weight: 500;
  border: 1px solid #ff0000 !important;
  border-radius: 10px !important;
}
.crud-row {
  padding-top: 10px;
  padding-bottom: 20px;
}
.crud-form-group {
  margin-bottom: 40px;
}
.nameFormatter {
  color: #000;
  font-weight: 500;
  font-size: 12px;
}
.nameFormatter:hover {
  text-decoration: none;
  color: #01223a !important;
}
.crud-form-interm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crud-form-group.crud-interm {
  width: 49%;
  position: relative;
}
.customy-label {
  display: inline-block;
  position: absolute;
  right: 4%;
  bottom: 14%;
  font-size: 18px;
  color: #8b8b8b;
}
.crud-col-item.pricing-cruded {
  padding: 15px 30px;
  background: #efefef;
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-bottom: 40px;
}
.taux-ipm-container,
.plafond-ipm-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.taux-ipm-label,
.plafond-ipm-label {
  color: #0c0b0b;
  font-weight: 400;
}
.taux-ipm-value,
.plafond-ipm-value {
  color: #0c0b0b;
  font-weight: 600;
}
input[type="date" i] {
  padding-right: 5px;
}
.soinsy,
.beny {
  top: 7%;
}
.header-hiddenable-section {
  position: absolute;
  right: 20%;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 18px;
  top: 90%;
  width: 23%;
}
.heder-hiddenable-title {
  color: #000000;
  padding: 7px;
  padding-left: 15px;
}
.header-hiddenable-ul li {
  list-style: none;
  padding: 12px 0;
  border-bottom: 1px solid #ececec;
  padding-left: 15px;
}
.header-hiddenable-ul {
  padding-left: 0;
}
.header-hiddenable-ul li:first-child {
  border-top: 1px solid #ececec;
}
.header-hiddenable-link {
  color: #000000;
  font-weight: 500;
}
.header-hiddenable-section:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 20px solid #ffffff;
  position: absolute;
  right: 20%;
  top: -6%;
}
.header-hiddenable-ul li:hover {
  background: rgb(2, 166, 156);
}
.header-hiddenable-ul li:hover .header-hiddenable-ul li a {
  /*     color: #fff !important; */
  text-decoration: none;
}

@keyframes grow {
  0% {
    transform: scale(3);
    opacity: 0;
  }
  50% {
    display: block;
    transform: scale(2);
  }
  100% {
    /* opacity: 1; */
    transform: scale(1);
  }
}
.header-hiddenable-section.animated {
  animation: grow 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.header-hiddenable-notification-section {
  position: absolute;
  right: 7%;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
  top: 79%;
  width: 23%;
}
.header-hiddenable-notification-section:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 20px solid #ffffff;
  position: absolute;
  right: 45%;
  top: -7%;
}
.header-hiddenable-notification-title {
  padding: 12px 17px;
  border-bottom: 1px solid #ececec;
  margin-left: -15px;
  margin-right: -15px;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
}
.header-hiddenable-notification-li {
  list-style: none;
  padding: 7px 15px;
  border-bottom: 1px solid #ececec;
}
.header-hiddenable-notification-ul {
  padding-left: 0;
  margin-left: -10px;
  margin-right: -10px;
}
.header-hiddenable-notification-li p {
  font-size: 12px;
  color: #000000;
  font-weight: 500;
}
.header-hiddenable-notification-li:last-child {
  border-bottom: none;
}
.header-hiddenable-notification-li span {
  color: #8a8989;
  font-size: 11px;
}
.header-hiddenable-notification-li:hover .notification-item-link p,
.header-hiddenable-notification-li:hover .notification-item-link,
.header-hiddenable-notification-li:hover .notification-item-link span {
  text-decoration: none !important;
  color: #02a69c !important;
}
.notification-item-link:visited,
.notification-item-link:active,
.notification-item-link:focus {
  text-decoration: none !important;
  /* color: #02A69C !important; */
}
.easypm-badge-success {
  background: #28a745 !important;
  padding: 5px 15px !important;
  width: auto !important;
  height: auto !important;
  font-size: 15px !important;
}
.easypm-badge-danger {
  background: #dc3545 !important;
  padding: 5px 15px !important;
  width: auto !important;
  height: auto !important;
  font-size: 15px !important;
}

.easypm-badge-secondary {
  background: #1f497d !important;
  padding: 5px 15px !important;
  width: auto !important;
  height: auto !important;
  font-size: 15px !important;
}

.assureur-badge {
  background: #005d86 !important;
  padding: 5px 15px !important;
  width: auto !important;
  height: auto !important;
  font-size: 15px !important;
}

.easypm-badge-default {
  background: #02a69c !important;
  padding: 5px 15px !important;
  width: auto !important;
  height: auto !important;
  font-size: 15px !important;
}

.easypm-badge-primary {
  background: indianred !important;
  padding: 5px 15px !important;
  width: auto !important;
  height: auto !important;
  font-size: 15px !important;
}
.easypm-badge-warning {
  padding: 5px 15px !important;
  width: auto !important;
  height: auto !important;
  font-size: 15px !important;
}
.nameFormation-noLink,
.name {
  white-space: normal;
  text-transform: uppercase;
  font-size: 12px;
}
.easypm-tabs-nav-pills-item a.active {
  background: transparent !important;
  color: #02a69c !important;
  font-weight: 600;
  border-bottom: 2px solid #02a69c !important;
  border-radius: 0 !important;
}
.easypm-tabs-nav-pills-item a {
  color: #000;
  font-size: 15px;
  font-weight: 400;
}
.easypm-tabs-content {
  margin-top: 45px;
}
.easypm-tabs-item .crud-submit-btn {
  width: 100%;
}
.easypm-tabs-nav-pills-item a {
  padding-left: 0;
  margin-right: 35px;
  padding-right: 0;
}
.adding-col-offseting div {
  width: 100%;
}
/* Landing Page Styles */
section.page-garde-container {
  background: linear-gradient(180deg, #02a69c 0%, #046685 100%);
  /* height: 100vh; */
  width: 100vw;
  /* position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px; */
  /* text-align: center; */
}
.introduction {
  color: #ffffff;
  font-weight: bold;
  font-size: 50px;
  padding: 100px 10px 15px 10px;
  text-transform: uppercase;
}
.introduction span {
  font-size: 25px;
  text-transform: none !important;
}
.introduction-slogan {
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  /* text-align: center;
  padding: 1% 23%; */
  padding: 10px;
}
.introduction-slogan p {
  color: rgba(255, 255, 255, 0.75);
}
.introduction-action-btn-container {
  padding: 20px;
}
.login-empty-header {
    background: #C4C4C4;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    padding: 10px 40px;
}
.intro-action-btn {
  background: #ffffff;
  width: 18%;
  padding: 15px;
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 600;
  color: #333333;
}
.easypm-in-macbook-image-container {
  /* position: absolute;
  top: 85%; */
  width: 100%;
  padding-top: 145px;
  /* z-index: 99999; */
}
.container-padding-bottom {
    padding-bottom: 6rem;
}
.easypm-in-macbook-image-content {
  text-align: center;
}
img.easypm-in-macbook-image {
  width: 100%;
}
.landing-page-component .empty-fluidy {
  z-index: 999999;
}
.empty-header.landing-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.auth-btn-container-assurance {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .login-btn-container-assurance {
      width: 100%;
  }
  .ldpg-login-btn-assurance {
    width: 100%;
    background: #005D86;
    color: #FFFFFF;
  }
  .ldpg-register-btn-assurance {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border: 1px solid #005D86;
  }
  .auth-btns-assurance {
    padding: 10px 40px;
    text-transform: uppercase;
    border-radius: 10px;
    font-weight: 600;
  }
  .ldpg-login-btn-assurance:hover {
    color: #005D86 !important;
    background: #FFFFFF;
    border: 1px solid #005D86;
  }
  .ldpg-register-btn-assurance:hover {
    color: #FFFFFF;
    background: #005D86 !important;
    border: 1px solid #FFFFFF;
  }
.auth-btn-container {
    display: flex;
    align-items: center;
    justify-content: end;
}
.login-btn-container {
  width: 100%;
}
.ldpg-login-btn {
  width: 100%;
  background: #02a69c;
  color: #ffffff;
}
.ldpg-register-btn {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border: 1px solid #02a69c;
}
.auth-btns {
    padding: 10px 40px;
    text-transform: uppercase;
    border-radius: 10px;
    font-weight: 600;
}
.li-navlink-easypm {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #394549 !important;
}
.li-navlink-easypm.active {
    color: #005D86 !important;
    border-bottom: 2px solid #005D86 !important;
    text-decoration: none !important;
}
.li-navlink-easypm-green {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #394549 !important;
}
.li-navlink-easypm-green.active {
    color: #02a69c !important;
    border-bottom: 2px solid #02a69c !important;
    text-decoration: none !important;
}
.ldpg-login-btn:hover {
  color: #02a69c !important;
  background: #ffffff;
  border: 1px solid #02a69c;
}
.ldpg-register-btn:hover {
  color: #ffffff;
  background: #02a69c !important;
  border: 1px solid #ffffff;
}
.why-easypm-section-container {
  padding-top: 9%;
  background: #ffffff;
  padding-bottom: 9%;
}
.hp-section-title {
  text-align: center;
  color: #01223a;
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 65px;
  font-style: normal;
}
.why-easypm-item-icon-container {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-bottom: 25px;
}
.why-easypm-item-icon-container.firsty {
  background: #01223a;
}
.why-easypm-item-icon-container.secondy {
  background: #03828a;
}
.why-easypm-item-icon-container.thirdy {
  background: #046685;
}
.why-easypm-icon {
  width: 65px;
  height: 65px;
}
.sous-section-title {
  color: #000000;
  font-weight: 600;
  font-size: 36px;
  padding-bottom: 20px;
}
.why-easypm-item-textual p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  text-align: justify;
}
.functionnalities-section-container {
  background: #ffffff;
  padding-bottom: 6%;
}
.functionnalities-col-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.functionnalities-image-item {
  width: 100%;
}
.functionnalities-content p,
.functionnalities-content div {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.7);
}
.functionnalities-content {
  padding-right: 45px;
}
.secondy .functionnalities-content {
  padding-right: 0;
  padding-left: 45px;
}
.secondy h2.sous-section-title {
  text-align: right;
}
img.functionnalities-image-item.easypm-app-mobile-image {
  width: 55%;
}
.functionnalities-content-image.easypm-app-mobile-image-container {
  text-align: right;
}
.testimonial-section-container {
  background: #ffffff;
  padding-bottom: 15%;
}
.quote-icon {
  width: 7% !important;
  position: absolute;
  left: 12%;
}
.testimonial-item-col.textual-col {
  position: relative;
}
.testimonial-item-col.textual-col p {
  padding-top: 10%;
  text-align: justify;
  padding-left: 10%;
  color: rgba(0, 0, 0, 0.7);
  font-weight: normal;
  font-size: 18px;
  line-height: 174.4%;
  padding-bottom: 10%;
}
.testimonial-image-item {
  width: 95px !important;
  height: 95px !important;
}
.testimonial-author-name {
  font-size: 24px;
  color: #000000;
  font-weight: 600;
}
.testimonial-author-infos-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.testimonial-image-container {
  text-align: left;
}
.testimonial-author-function {
  font-weight: normal;
  font-size: 18px;
  line-height: 174.4%;
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
}
.testimonial-container .dot {
  background: #c4c4c4 !important;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  border-radius: 0px !important;
  width: 15px !important;
  height: 15px !important;
}
.testimonial-container .dot.selected {
  background: #02a69c !important;
}
.contact-light-container {
  padding: 100px 30px;
  background: #03828a;
  position: relative;
}
.contact-light-col {
  text-align: center;
}
.contact-light-col.textual-contact-col {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 24px;
}
.contact-light-btn {
  background: #ffffff;
  width: 45%;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  padding: 16px;
}
.helpy-title {
  padding: 5px;
}
.contact-light-container:after {
  content: "";
  width: 32%;
  height: 100%;
  position: absolute;
  background: rgba(240, 240, 240, 0.05);
  top: 20%;
}
.contact-light-container:before {
  content: "";
  width: 10%;
  height: 50%;
  position: absolute;
  background: rgba(240, 240, 240, 0.05);
  top: 50%;
  right: 40%;
}
footer.ldp-footer {
  background: #fff;
  padding: 63px;
}
.ldp-footer-item-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
}
.ldp-footer-item.textual p {
  font-weight: normal;
  font-size: 16px;
  line-height: 174.4%;
  color: rgba(0, 0, 0, 0.7);
  text-align: justify;
}
.ldp-footer-link-list {
  padding-left: 0px;
}
.ldp-footer-link-list li {
  list-style: none;
  padding: 5px 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 174.4%;
  color: rgba(0, 0, 0, 0.7);
}
.footer-link-item {
  color: rgba(0, 0, 0, 0.7);
}
.footer-link-item:hover {
  color: #000000 !important;
  font-weight: 500;
  text-decoration: none;
}
.app-mobile-row,
.social-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-mobile-container {
  padding-bottom: 40px;
}
.social-container {
  padding-bottom: 20px;
}
/* End Landing Page Styles */

/* Register */
.register-real-form label {
  width: 100%;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
  padding-left: 5px;
}
.register-real-form input {
  padding: 6px;
  height: 45px;
}
.register-real-form .input-group {
  margin-bottom: 25px !important;
}
/*  */
.presty .table-filter-row.beneficiaire-filter {
  width: 30%;
}
ul.header-hiddenable-ul li:hover ul.header-hiddenable-ul li a,
ul.header-hiddenable-ul li a:hover {
  color: #fff !important;
  text-decoration: none;
}
.header-actions-label {
  color: #a2a2a2;
}
.header-actions-label:hover {
  text-decoration: none;
  font-weight: 500;
  color: #01223a !important;
}
.hiddenable-opacity {
  opacity: 0 !important;
}
.hiddenable {
  display: none !important;
}
.detaily .table-filter-row {
  width: 35% !important;
}
.react-bs-select-all {
  width: 14px;
  height: 14px;
}
.bill-generation-btn-container {
  width: 70%;
}
.export-btn.bill-generation-btn {
  background: #02a69c !important;
  color: #fff !important;
}
.bill-generation-btn-label {
  color: #fff !important;
}
.register-real-form select {
  width: 100% !important;
  background: transparent !important;
  padding-left: 5px !important;
}
.entry .table-filter-row.beneficiaire-filter {
  width: 30% !important;
}
.easypm-badge-info {
  height: auto !important;
  padding: 5px 15px !important;
  width: auto !important;
  font-size: 15px !important;
  background: #17a2b8 !important;
}
.userName {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

.alert-delete-message {
  text-align: center;
  padding: 30px 0;
}

.alert-delete-message span {
  font-size: 20px;
  font-weight: 500;
}

.buttons-delete {
  margin-left: 17rem;
  display: flex;
}

.buttons-delete .btn-danger {
  margin-right: 1rem;
}
.password-validation-terms-row {
  border-bottom: none !important;
}
.password-validation-terms-title {
  font-size: 16px;
  color: #043b63 !important;
}
.min-character-uppercase-ok,
.min-character-lowercase-ok,
.min-character-number-ok,
.min-character-count-ok,
.min-character-special-ok {
  color: #043b63 !important;
  font-weight: 700;
}
.type-presta-container {
  display: flex;
  text-align: center;
  justify-content: center;
  padding-top: 25px;
  background: #ececec;
  padding-bottom: 25px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.type-presta-label-container.type-presta-label-col {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.type-presta-input-radio-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.type-presta-input-radio-container label {
  display: block;
  margin-bottom: 0;
  padding-left: 6px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
}
.easypm-input-radio input {
  display: block;
}
.easypm-input-radio {
  width: 20px;
  height: 20px;
}
.type-presta-label {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
}
input.filter.text-filter.form-control {
  border-radius: 8px !important;
}
.prestataire-side {
  width: 100% !important;
}
.add-service-row {
  margin-top: 40px;
  margin-bottom: 70px;
}
.adding-item-container-icon.linkable {
  text-align: right;
}
.service-item-container {
  border: 0.5px solid #95959514;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
}
.service-item-value {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
}
.service-name-label {
  font-size: 15px;
  font-weight: 500;
}
.hiddenable-crud-system,
.hiddenable-form {
  animation: bouncingFromLeft ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: bouncingFromLeft ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: bouncingFromLeft ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: bouncingFromLeft ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: bouncingFromLeft ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
}
.hiddenable-crud-container.inputify-container {
  position: relative;
}
.buttonify-container {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
}
.submit-button {
  height: 45px;
  border-radius: 10px;
  background: #01223a !important;
  border-color: #01223a !important;
}

@keyframes bouncingFromLeft {
  0% {
    opacity: 0;
    transform: translate(-1500px, 0px);
  }
  60% {
    opacity: 1;
    transform: translate(30px, 0px);
  }
  80% {
    transform: translate(-10px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes bouncingFromLeft {
  0% {
    opacity: 0;
    -moz-transform: translate(-1500px, 0px);
  }
  60% {
    opacity: 1;
    -moz-transform: translate(30px, 0px);
  }
  80% {
    -moz-transform: translate(-10px, 0px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes bouncingFromLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate(-1500px, 0px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate(30px, 0px);
  }
  80% {
    -webkit-transform: translate(-10px, 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes bouncingFromLeft {
  0% {
    opacity: 0;
    -o-transform: translate(-1500px, 0px);
  }
  60% {
    opacity: 1;
    -o-transform: translate(30px, 0px);
  }
  80% {
    -o-transform: translate(-10px, 0px);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes bouncingFromLeft {
  0% {
    opacity: 0;
    -ms-transform: translate(-1500px, 0px);
  }
  60% {
    opacity: 1;
    -ms-transform: translate(30px, 0px);
  }
  80% {
    -ms-transform: translate(-10px, 0px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}

.input-infos-container {
  font-size: 11px;
  display: flex;
  align-items: center;
}
.export-container .export-btn-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.modele-import-btn {
  box-shadow: 0px 4px 10px rgb(0 0 0 / 7%);
  border-radius: 10px;
  border-color: transparent !important;
  width: 29%;
  color: #fff !important;
}
.functionnalities-row-item {
  margin-bottom: 35px;
}
.page-title-container {
  width: 100% !important;
  display: flex;
}
.page-title.entreprise-side {
  line-height: normal !important;
}

/* Contact Form Style */
.contact-form-col {
  margin-top: 25px;
}
.contact-form-title {
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
}
.contact-form-row label {
  color: #fff;
  font-weight: 500;
}
/* .contact-form-row input,
.contact-form-row textarea {
  color: #fff !important;
}
.contact-form-row input::placeholder {
  color: #ffffff54 !important;
} */
.contact-form-row input::-webkit-input-placeholder,
.contact-form-row textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff44 !important;
}
.contact-form-row input::-moz-placeholder,
.contact-form-row textarea::-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff54 !important;
}
.epm-alert-danger {
  width: 100%;
}
.easypm-alert-danger {
  width: 100%;
}

.easypm-alert {
  border-left: 6px solid #68d5c4;
  border-radius: 0.4rem;
  color: #000;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 5px;
  width: 100%;
  background-color: rgba(104, 213, 196, 0.2);
}

.easypm-alert svg {
  font-size: 34px;
  animation: animate 6s linear infinite;
}

.easypm-alert h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #000;
  margin: 0;
}

@keyframes animate {
  0% {
    transform: rotateX(-20deg) rotateY(-360deg);
  }

  100% {
    transform: rotateX(-20deg) rotateY(0deg);
  }
}
.crud-submit-btn.delete-actions {
  width: 100%;
  margin: 0 15px;
}
.display-none {
  display: none !important;
}
.select-crud-form {
  box-shadow: none !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.adding-item-container.admin {
  width: 7% !important;
}
.drop-flag {
  background-color: #fff !important;
  border: 1px solid transparent !important;
  border-radius: 3px 0 0 3px;
}
.pourcentage {
  display: inline-block;
  position: absolute;
  top: 25%;
  right: 20%;
  font-size: 30px;
  color: #80808061;
}
.custom-devise {
  display: inline-block;
  position: absolute;
  top: 31%;
  right: 20%;
  font-size: 20px;
  color: #80808061;
}

.form-group.required label:after {
  content: "*";
  color: red;
}

.required label:after {
  content: "*";
  color: red;
}

.link-add {
  margin: 0.1rem;
  margin-bottom: 1rem;
  margin-top: -1rem;
}

.link-add-dashboard {
  margin: 0.2rem;
}

.css-yk16xz-control {
  border-radius: 10px !important;
  padding: 4px !important;
}

.rs-btn-toggle-checked {
  background-color: lightseagreen !important;
}

.rs-btn-toggle-checked:hover {
  background-color: lightseagreen !important;
}

.profile-avatar-container {
  width: 125px;
  height: 125px;
  overflow: hidden;
  position: relative;
  margin: 15px auto;
  border: 1px solid #ddd;
  border-radius: 50%;
  cursor: pointer;
}

.profile-avatar-container img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.profile-avatar-container span {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 50%;
  background: #fff8;
  text-align: center;
  font-weight: 400;
  color: #20c997;
  transition: 0.3 ease-in-out;
}

.profile-avatar-container:hover span {
  bottom: -15%;
}

.profile-avatar-container span.charge {
  bottom: -15%;
}

.profile-avatar-container #file_up {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
::-webkit-file-upload-button {
  cursor: pointer;
}

button.btn.btn-primary.crud-submit-btn {
  margin-left: 5%;
}
.easypm-player {
  border-radius: 50px !important;
  border: 5px solid #03828a;
  background: #03828a !important;
}
.react-player__preview {
  border-radius: 50px;
}
.easypm-player video {
  border-radius: 50px !important;
}

.rs-message.rs-message-warning.rs-message-show.rs-message-has-title.rs-message-has-icon {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.version-container {
  font-weight: 600;
  font-size: 18px;
  color: #02a69c;
  z-index: 9999;
  padding: 11px;
  text-align: center;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: orange !important;
}

.easypm-collapse-body {
  background-color: #ffffff;
}

.table-bordered thead {
  border: 1px solid #dee2e6 !important;
}

.table-bordered tbody tr td {
  padding: 1rem !important;
}

.category-name-input {
  padding: 26px 15px;
}
.subcat-manage-container {
  padding: 20px 30px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  position: relative;
}
.subcat-manage-title {
  width: 40%;
  background: #fff;
  text-align: center;
  font-size: 16px;
  color: #00000099;
}
.crud-submit-btn {
  width: 100%;
}
.icon-trash svg:hover {
  color: #ff0000b8 !important;
}
.rs-uploader-file-items {
  display: none !important;
}
.empty-subcategory-infos {
  color: #ff0000ba;
  font-style: italic;
  font-size: 13px;
}
.sub-categories-container {
  display: flex;
  flex-wrap: wrap;
}

.icon-type-prestation {
  height: 66px;
  width: 66px;
  color: #20c997;
}
.text-category {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #0c0b0b;
}
.category-prestation {
  flex-direction: column;
  align-items: center;
}
.bloc-img-prestation {
  background: #736767;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}
.type_presta_image {
  width: 120px;
}
.shadow-category {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.sub-category-item {
  background: #063743;
  padding: 5px 15px;
  margin-right: 15px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 7px;
}
.sub-categories-container.pl-3 {
  margin: 15px 0px;
}
.sous-titre-categorie {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.bloc-category-inline {
  display: inline-block;
}
.titre-category-prestation {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.bloc-liste {
  display: inline-block;
}
.bloc-icon {
  border: 1px solid #20c997;
  border-radius: 50%;
  padding: 0.5rem;
  color: #c8c8c8;
  width: 40px;
  height: 40px;
  display: inline-block;
}
.input-add-category {
  background: #ffffff;
  border: 1px solid #ca8d19 !important;
  box-sizing: border-box;
  border-radius: 10px;
}
.icon-trash {
  position: absolute;
  top: 9px;
  right: 11px;
  color: #130f40;
}
.input-add-new-category {
  background: #f4f4f4;
  border-radius: 10px;
}
.icon-plus {
  color: #ca8d19;
  position: absolute;
  top: 11px;
  left: 6px;
}
.input-add-category {
  background: #fff;
  border: 1px solid #ca8d19 !important;
  box-sizing: border-box;
  border-radius: 10px;
}
.icon-image {
  left: 15px;
  position: absolute;
  top: 13px;
}
.add-subcat-item-container {
  text-align: center;
}
.add-item-container-sub {
  width: 100%;
  padding: 10px;
  background: #02a69c !important;
  border-radius: 10px;
  color: #fff;
  border: 1px solid #20c997 !important;
  cursor: pointer;
}

.row.sub-cat-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.swal2-styled.swal2-confirm {
  background: #02a69c !important;
}

.swal2-styled.swal2-cancel {
  background: red !important;
}

.submit-btn-row {
  margin: 50px 0;
}

/* .react-bs-table table td,
.react-bs-table table th {
} */

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  padding: 0 1.5rem;
}

#chartdiv1 {
  width: 100%;
  height: 200px;
}

.fact-cls {
  /* text-align: center; */
  vertical-align: middle !important;
  /* font-size: 0.7rem; */
  font-weight: 400;
  color: #000000;
  background: transparent !important;
}
.btn-assure-import {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    width: 100%;
    padding: 1rem;
}
.btn-assure-telecharger {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    background: #005D86;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    width: 100%;
    padding: 1rem;
    text-decoration: none !important;
}

.header-hiddenable-notification-ul {
    overflow-y: scroll;
    height: 300px;
}

.header-hiddenable-notification-ul::-webkit-scrollbar {
    width: 0px;
}

.header-hiddenable-notification-ul::-webkit-scrollbar-track {
    background: transparent;
}

.header-hiddenable-notification-ul::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 3px solid transparent;
}

.ql-editor {
  min-height: 500px;
}

.large-quill {
  padding: 0 !important;
}

.large-quill .ql-editor p {
  color: #000 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  /* color: rgba(0, 0, 0, 0.5); */
}

.content-col-left-banner-landingpage {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
}

.titre-mentions-legales {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
}

.text-mention-legales {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

.text-conditions,
.text-conditions p {
  font-family: "Roboto";
  font-display: "swap";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  text-align: justify;
}

.text-conditions p {
  margin-bottom: 1px;
}